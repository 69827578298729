/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/skeleton.css"
import "./src/styles/normalize.css"
import "./src/styles/custom.css"
require("prismjs/themes/prism.css")
